<template>
	<ManageFilter
		:search-value.sync="queryParams.q"
		:filter-value.sync="dropdown.status"
		:filter-options="CATEGORY_ATTRIBUTE_STATUS_OPTIONS"
		:is-loading="isListLoading"
		:list="lists"
		:table-fields="TABLE_ATTRIBUTE_KEY_FIELDS"
		:table-pagination="tablePagination"
		@update:filter-value="handleFilter"
		@onSearch="handleSearch"
		@onSwitch="handleSwitch"
		@onPageChange="handlePageChange"
	/>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ManageFilter from '../components/ManageFilter.vue';
import { cleanObject, randomSearchString, getSelectedValueDropdown } from '../assets/js/helpers';
import { CATEGORY_ATTRIBUTE_STATUS_OPTIONS, TABLE_ATTRIBUTE_KEY_FIELDS, MAPPING_SWITCH_VALUE_TO_STATUS } from '../enums/categories';
import { transformedManageFilterList } from '../assets/js/transform/category';

export default {
	name: 'CategoryAttributeEdit',
	components: {
		ManageFilter,
	},
	data() {
		return {
			TABLE_ATTRIBUTE_KEY_FIELDS,
			CATEGORY_ATTRIBUTE_STATUS_OPTIONS,
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				category_ids: this.$route.params.id || null,
				status: this.$route.query.status || null,
				r: randomSearchString(),
			},
			dropdown: {
				status: getSelectedValueDropdown(this.$route.query.status, CATEGORY_ATTRIBUTE_STATUS_OPTIONS),
			},
			categoryId: this.$route.params.id,
		};
	},

	computed: {
		// Map State
		...mapState('attributeKeys', {
			list: 'list',
		}),
		...mapState('categories', {
			edit: 'edit',
		}),
		// Map Getters
		...mapGetters('categories', {
			attributeList: 'attributeList',
			categoryInfo: 'categoryInfo',
		}),

		// declare lists for table data components
		lists() {
			return transformedManageFilterList(this.attributeList);
		},
		isListLoading() {
			return this.list.isLoading || this.edit.isLoading;
		},
		tablePagination() {
			return {
				pages: this.list.meta.lastPage,
				activePage: this.list.meta.currentPage,
			};
		},
	},
	async created() {
		await this.fetchData();

		// add new breadcrumb
		this.addBreadcrumb({
			title: this.categoryInfo.name,
			route: {
				name: 'CategoryEdit',
			},
		});
	},

	methods: {
		...mapActions({
			addBreadcrumb: 'breadcrumb/addBreadcrumb',
			getCategory: 'categories/getCategory',
			updateAttributeStatus: 'categories/updateAttributeStatus',
			getAttributeStatus: 'categories/getAttributeStatus',
			getAttributeKeys: 'attributeKeys/getAttributeKeys',
		}),
		fetchData() {
			return Promise.all([
				this.getCategory(this.categoryId),
				this.getAttributeKeys(this.queryParams),
			]);
		},
		updateUrlParams() {
			const query = cleanObject(JSON.parse(JSON.stringify(this.queryParams)));
			this.$router.push({ query });
		},
		handleSearch() {
			this.handlePageChange(null);
		},
		handlePageChange(page) {
			this.queryParams = {
				...this.queryParams,
				page,
			};

			this.updateUrlParams();
		},
		handleSwitch({ id, value }) {
			const params = {
				attribute_key_id: id,
				action: MAPPING_SWITCH_VALUE_TO_STATUS[value],
			};

			// use vuex actions to call rest api, update status
			this.updateAttributeStatus({
				id: this.categoryId,
				params,
			});
		},
		handleFilter({ value }) {
			this.queryParams = {
				...this.queryParams,
				page: null,
				status: value,
			};

			this.updateUrlParams();
		},
	},
};
</script>
